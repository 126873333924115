.react-tel-input .MuiInputAdornment-positionStart .MuiSelect-selectMenu {
  padding-left: 6px !important;
  padding-right: 24px !important;
}

.MuiButtonBase-root.Mui-disabled.Mui-selected {
  background-color: #fff !important;
}

/* add a border around .MuiButtonBase-root when focused */
.MuiButtonBase-root:focus {
  outline: 0;
  border: 2px solid #212a5e;
}

/* add a border around .MuiButtonBase-root when hovered */
.MuiButtonBase-root:hover {
  outline: 0;
  border: 2px solid #212a5e;
}

/* add a border around .MuiButtonBase-root when active */
.MuiButtonBase-root:active {
  outline: 0;
  border: 2px solid #212a5e;
}

/* add a border around .MuiButtonBase-root and type is submit when focused */
.MuiButtonBase-root[type="submit"]:focus {
  border-width: 4px;
}

/* add a border around .MuiButtonBase-root and type is submit when hovered */
.MuiButtonBase-root[type="submit"]:hover {
  border-width: 4px;
}

/* add a border around .MuiButtonBase-root and type is submit when active */
.MuiButtonBase-root[type="submit"]:active {
  border-width: 4px;
}

fieldset {
  border: none;
}
